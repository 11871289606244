/*Categories*/

.products-page {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 100vh;
}

.products-container {
    display: flex;
    flex: 1;
}

*{
    padding: 0;
    margin: 0;
}

.category-page {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 100vh;
}

.categories-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.categories-container h1{
    color: var(--dark-clr);
    font-size: 1.5rem;
    font-weight: bold;
}

.category-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top-color: var(--darker-contrast-clr);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .loading-container p {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
  }  