.under-header{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.under-tittle {
    display: flex;
    align-items: flex-start;
}

.back-button {
    margin: 4vh 0 0 4vh;
    background-color: transparent;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--darker-contrast-clr);
    justify-content: center; /* Центрирование текста внутри кнопки */
    position: relative; /* Для корректного отображения стрелки */
    width: auto; /* Кнопка занимает только необходимое пространство */
}

.back-button-block {
    display: flex;
    width: 100%;
    justify-content: start;
}

.back-button::before {
    content: '';
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-top: 2px solid var(--contrast-clr); /* Белая стрелка */
    border-left: 2px solid var(--contrast-clr); /* Белая стрелка */
    transform: rotate(-45deg);
    margin-right: 10px; /* Отступ между стрелкой и текстом */
}

.back-button:hover::before {
    border-color: var(--darker-complementary-clr); /* Изменение цвета стрелки при наведении */
}

.back-button:hover {
    color: var(--darker-complementary-clr); /* Изменение цвета текста при наведении */
}
