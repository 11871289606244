/* src/components/ShopCard.css */
.shop-card {
  border-radius: 10px;
  width: 250px;
  text-align: center;
  padding: 0.5rem;
  display: inline-block;
  overflow: hidden;
  background-color: var(--darker-complementary-clr);
  box-shadow: 0 4px 8px var(--contrast-clr);
  transition: 0.3s;
  margin: 1rem;
}

.shop-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}


.shop-card-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}


.shop-image {
    border-radius: 10px;
    max-width: 250px;
    object-fit: cover;
    max-height: 250px;
    min-height: 250px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.shop-title h3{
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    text-decoration: none;
    border-bottom: 0.05rem solid var(--darker-contrast-clr);
    padding: 0.5rem 0;
}

.shop-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0.25rem;
  align-items: flex-start;
  width: 100%;
}

.shop-info .description {
  font-size: 0.8rem;
  padding: 0.5rem 0;
}

.shop-info .owner-info {
  font-style: italic;
  font-size: 0.7rem;
}

@media only screen and (max-width: 425px) {
  .shop-card{
      width: 300px;
  }
  .shop-image{
      height: 150px;
  }
}