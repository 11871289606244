/* src/components/Footer.css */
.footer {
    background-color: var(--darker-complementary-clr);
    color: #ffffff;
    text-align: center;
    justify-content: center;
    display: flex;
  }

.footer-content {
    /*display: flex;*/
    flex-wrap: wrap;
    flex-direction: column;
    /*justify-content: left;*/
    /*align-items: flex-start;*/
}
  
  .footer-content p {
    padding: 1vh 0;
    color: var(--dark-clr);
  }

    #contacts {
    font-weight: bold;
  }

  .footer-content a {
    color: var(--contrast-clr) ;
    text-decoration: none;
      font-style: italic;
      text-transform: none;
  }

  .footer-content a:hover {
    text-decoration: underline;
  }
  