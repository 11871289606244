/*Dark pink palette*/

:root{
  --primary-clr: #F4EEFF;
  --complementary-clr: #DCD6F7;
  --darker-complementary-clr: #A6B1E1;
  --contrast-clr: #4d5388;
  --darker-contrast-clr: #363a5d;}

/*!*Green palette*!*/
/*:root {*/
/*  --primary-clr: #F0F0D7; !* Мягкий пастельно-зелёный *!*/
/*  --complementary-clr: #B2C9AD; !* Светло-зелёный оттенок *!*/
/*  --darker-complementary-clr: #91AC8F; !* Чуть более насыщенный зелёный *!*/
/*  --contrast-clr: #66785F; !* Контрастный тёмно-зелёный *!*/
/*  --darker-contrast-clr: #4B5945; !* Ещё темнее, глубокий зелёный *!*/
/*  --dark-clr: #2E4D3A; !* Почти хвойный зелёный *!*/
/*  --arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);*/
/*}*/

:root {
  --dark-clr: #2c2c2c;
  --arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
}


* {
  font-family: 'Main', sans-serif;
  font-weight: 900;
  text-transform: none;
  text-decoration: none;
}

ul {
  list-style: none;
}

.email {
  text-transform: none;
  font-weight: 400;
}

a {
  cursor: pointer;
}


body {
  margin: 0;
  min-height: 100vh;
  font-family: 'Main', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-clr);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.service-page {
  background-color: var(--primary-clr);
  color: var(--dark-clr);
}
.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@font-face {
  font-family: "Main";
  src: url("../public/Main-font/Poppins-Regular.ttf") format("truetype");
}