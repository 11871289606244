/* src/components/Header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--darker-complementary-clr);
  color: white;
  /*position: sticky;*/
  top: 0;
  left: 0;
  /*z-index: 1000;*/
  /*width: 100%;*/
}

.profile-photo:hover, .auth-buttons img:hover{
transform: scale(1.15);
}

.logo {
display: flex;
align-items: center;
margin-left: 3%;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none; /* Убираем подчеркивание */
  color: white; /* Цвет текста */
}

.logo-image {
  height: 10vh; /* Или другой подходящий размер */
  margin-right: 10vw; /* Отступ между логотипом и текстом */
}

.logo-text {
  font-size: 2rem; /* Или другой подходящий размер */
  font-family: "Extra", serif;
  font-weight: bold;
}

.auth-buttons {
display: flex;
gap: 2rem;
margin-right: 2.5rem;
font-family: Poppins, serif;
}
@media only screen and (max-width: 712px) {
  .auth-buttons {
    margin-right: 40px;
  }
  .logo-text{
    font-size: 14px;
  }
}

/* pretty login button */
.login_button {
width: 80px;
height: 30px;
border: none;
outline: none;
color: var(--complementary-clr);
background: var(--dark-clr);
cursor: pointer;
position: relative;
z-index: 0;
border-radius: 20px;
}

.login_button:before {
content: '';
background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
position: absolute;
top: -2px;
left:-2px;
background-size: 400%;
z-index: -1;
filter: blur(5px);
width: calc(100% + 4px);
height: calc(100% + 4px);
animation: glowing 20s linear infinite;
opacity: 0;
transition: opacity .3s ease-in-out;
border-radius: 10px;
}

.login_button:active {
color: var(--dark-clr)
}

.login_button:active:after {
background: transparent;
}

.login_button:hover:before {
opacity: 1;
}

.login_button:after {
z-index: -1;
content: '';
position: absolute;
width: 100%;
height: 100%;
background: var(--dark-clr);
left: 0;
top: 0;
border-radius: 20px;
}

@keyframes glowing {
0% { background-position: 0 0; }
50% { background-position: 400% 0; }
100% { background-position: 0 0; }
}
.profile-photo{
width: 32px;
height: 32px;
border-radius: 50%;
object-fit: cover;
}

.cart-icon {
  position: relative;
  display: inline-block;
}

.cart-quantity {
  position: absolute;
  bottom: -6px;
  right: -10px;
  background-color: var(--dark-clr);
  color: var(--complementary-clr);
  font-size: 0.75rem;
  font-weight: bold;
  padding: 1px 5px;
  border-radius: 10px;
  line-height: 1;
  text-align: center;
}