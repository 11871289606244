/* src/components/ProductCard.css */
.products-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.products-container h1 {
  margin-bottom: 2vh;
  color: var(--darker-contrast-clr);
  font-weight: bold;
}

.product-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.product-card {
  background-color: var(--darker-complementary-clr);
  border-radius: 10px;
  width: 250px;
  text-align: center;
  padding: 0.5rem;
  display: inline-block;
  overflow: hidden;
  box-shadow: 0 4px 8px var(--contrast-clr);
  transition: 0.3s;
  margin: 1rem;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.add-to-cart-button {
  background-color: var(--darker-contrast-clr);
  color: white;
  font-size: 0.75rem;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.add-to-cart-button:hover {
  background-color: var(--dark-clr);
  color: var(--complementary-clr);
}

.product-details {
  display: flex;
  flex-direction: column;
}

.product-title {
  font-size: 1rem;
  font-weight: bold;
  padding: 1vh 0;
  text-transform: capitalize;
}

.product-description {
  font-size: 0.75rem;
  max-height: 70px;
  margin: 2vh 0 10vh 0;
}

.product-footer {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.product-price {
  font-size: 0.75rem;
  font-style:italic;
  align-content: center;
  margin: 10px 0;
}

.product-image {
  border-radius: 10px;
  max-width: 250px;
  object-fit: cover;
  max-height: 250px;
  min-height: 250px;
}

.add-to-cart-button.disabled-button {
  background-color: grey !important;
  cursor: not-allowed;
  opacity: 0.6;
}

.add-to-cart-button:disabled {
  background-color: grey !important;
  cursor: not-allowed;
  opacity: 0.6;
}

.disabled-button {
  background-color: grey !important;
  cursor: not-allowed;
  opacity: 0.6;
}

@media only screen and (max-width: 720px) {
  .product-card {
    width: 280px;
  }
  .product-description {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: fit-content;
  }
  .product-title {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: fit-content;
  }
  .product-footer {
    flex-direction: column;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .product-price {
    margin-left: auto;
    margin-right: auto;
  }
}
