/* src/pages/HomePage.css */
/* General Styles */
* {
  color: var(--darker-contrast-clr);
  margin: 0;
}

.home-page {
  background-color:var(--primary-clr); /* Warm, light beige background */
  color: var(--darker-contrast-clr); /* Warm gray text color */
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Hero Section */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 50px 10%;
  background: var(--complementary-clr); /* Warm pink and beige gradient */
}

.hero-content {
  max-width: 60%;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--contrast-clr);
}

.hero-content h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.cta-button {
  background-color: var(--contrast-clr);
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  margin-bottom: 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.cta-button:hover {
  background-color: var(--dark-clr);
  color: var(--complementary-clr);
}

.hero-image {
  max-width: 50%;
  border-radius: 20px;
  margin-left: 3vw;
}

/*Shops Section*/
.shops-section {
  /*display: flex;*/
}

.shops-section h3 {
  font-size: 1.5rem;
  text-align: center;
  color: var(--darker-contrast-clr);
  padding: 1.5rem 0 0 0;
}

.shops-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* Featured Section */
.featured-section {
  text-align: center;
  padding: 40px 10%;
}

.featured-section h3 {
  font-size: 32px;
  margin-bottom: 30px;
  color: var(--dark-clr);
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

/* About Section */
.about-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 10%;
  background-color: var(--complementary-clr); /* Light cream background */
}

.about-section h3 {
  font-size: 32px;
  color: var(--contrast-clr);
  margin-bottom: 20px;
  margin-right: 10px;
}

.about-section p {
  font-size: 18px;
  line-height: 1.6;
  max-width: 60%;
}

.about-image {
  max-width: 30%;
  border-radius: 10px;
  margin-left: 15px;
}


/* Responsive Design */
@media only screen and (max-width: 768px) {
  .hero-section, .about-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-content, .about-section p {
    max-width: 100%;
  }

  .hero-image, .about-image {
    max-width: 80%;
  }
}