h1 {
  margin: 1vh 0;
  color: var(--darker-contrast-clr);
  justify-content: center;
}

.service-page {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.cart-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
}

.cart-items ul {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
}

.cart-items {
  display: flex;
  justify-content: space-between;
}

.info-inner-blocks{
  display: flex;
  justify-content: space-evenly;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 800px;
  padding: 25px 40px;
  border-radius: 10px;
  margin-bottom: 2vh;
}

.item-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 1rem;
}

.item-title {
  font-size: 1rem;
  margin-bottom: 1vh;
}

.item-description {
  font-size: 0.75rem;
  color: var(--dark-clr);
}

.cart-item-actions {
  display: flex;
  flex-direction: column;
}

.left-block {
  flex: 1;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  min-width: 14vw;
}

.info-container {
  max-width: 60%;
  overflow-wrap: break-word;
}

.add-sub-but{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.quantity-button {
  background: var(--complementary-clr);
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}
.quantity-button:hover {
  background: var(--darker-complementary-clr);
  transform: scale(1.1);
}

.quantity-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.quantity {
  margin: 0 0.5rem;
  font-size: 1rem;
}

.remove-button {
  background: var(--complementary-clr);
  border: none;
  margin: 1vh 0;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3vh;
  width: 2vw;
}

.remove-button:hover {
  background: var(--darker-complementary-clr);
  transform: scale(1.1);
}

.right-block {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: right;
}

.item-total-price {
  font-size: 0.75rem;
  font-style: italic;
  padding: 15px;
  border-radius: 10px;
}

.total-price {
  color: var(--darker-contrast-clr);
  font-size: 1rem;
  font-style: italic;
}

.payment-block {
  max-width: 50vh;
  width: 50vh;
  padding: 25px 40px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 2vh;
  max-height: fit-content;
}

.payment-block h3 {
  font-size: 24px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

.address-form {
  padding: 30px 0 0 0;
}

.address-form .form-row {
  display: flex;
  margin: 32px 0;
  flex-wrap: wrap;
}

.address-form .input-data {
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}

.address-form input {
  display: block;
  width: 100%;
  height: 40px;
  border: none;
  font-size: 1rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  margin: 2vh 0;
}

.address-form .input-data input:focus ~ label, .address-form  .textarea textarea:focus ~ label,
.address-form  .input-data input:valid ~ label, .address-form  .textarea textarea:valid ~ label{
  transform: translateY(-20px);
  font-size: 14px;
  color: var(--contrast-clr);
  font-weight: 500;
}

.address-form  textarea:focus, input:focus{
  outline: none;
}

.address-form label {
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  font-size: 14px;
  transition: all 0.3s ease;
  font-weight: 500;
  opacity: 70%;
}

.address-form .underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}

.address-form .underline:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: var(--darker-complementary-clr);
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.address-form input:focus ~ .underline:before,
.address-form input:valid ~ .underline:before {
  transform: scale(1);
}

.address-button {
  padding: 0.3rem 1.5rem;
  border: none;
  outline: none;
  color: #fff;
  background: var(--darker-complementary-clr);
  cursor: pointer;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.4s ease;
}

.address-button:hover {
  background: var(--contrast-clr);
}

.total-price {
  margin-top: 20px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.payment-button {
  display: block;
  margin: 20px auto 0;
  padding: 0.3rem 1.5rem;
  border: none;
  outline: none;
  color: #fff;
  background: var(--darker-complementary-clr);
  cursor: pointer;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.4s ease;
}

.payment-button:hover {
  background: darkseagreen;
}

.payment-button.disabled-button {
  background-color: grey;
  cursor: not-allowed;
  opacity: 0.6;
}

@media (max-width: 700px) {
  .payment-block {
    padding: 15px 20px;
  }

  .address-form .form-row {
    flex-direction: column;
  }

  .address-button,
  .payment-button {
    width: 100%;
  }
}


@media only screen and (max-width: 720px) {
  .item-info {
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cart-item, .payment-block {
    flex-direction: column;
    align-items: center;
    width: 80vw;
    padding: 2vh 5vw;
  }
  .cart-item-actions {
    align-items: center;
    flex-direction: row;
  }

  .info-inner-blocks {
    flex-direction: column;
    align-items: center;
  }
  .item-total-price {
    background-color: inherit;
    padding: 0;
    margin: 0;
  }
  .quantity-button{
    width: 60%;
    height: 40px;
  }
  .remove-button{
    width: 30%;
    height: 40px;
  }

  .cart-items {
    flex-direction: column;
    align-items: center;
  }
}
