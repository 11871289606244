/* src/pages/PaymentPages.css */
.payment-success, .payment-failure {
  display: flex;
  min-height: 100vh;
  text-align: center;
  flex-direction: column;
}

.content {
  min-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 10px;
  width: 40vw;
  text-align: center;
  overflow: hidden;
  justify-content: center;
  /*background-color: white;*/
  transition: 0.3s;
  flex: 1;
}

.info strong {
  font-style: italic;
}


.btn-gallery,
.btn-profile {
  margin: 2vh;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.btn-gallery, .btn-profile {
  border: none;
  outline: none;
  font-size: 1rem;
  color: white;
  background: var(--contrast-clr);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 20px;
}

.payment-success button:hover {
  background-color: var(--dark-clr);
  color: var(--complementary-clr);
}
