.category-card {
  background-color: var(--darker-complementary-clr);
  border-radius: 10px;
  width: 250px;
  text-align: center;
  padding: 0.5rem;
  display: inline-block;
  overflow: hidden;
  box-shadow: 0 4px 8px var(--contrast-clr);
  transition: 0.3s;
  margin: 1rem;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.category-image {
  border-radius: 10px;
  max-width: 250px;
  object-fit: cover;
  max-height: 250px;
  min-height: 250px;
}

.category-title {
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  text-decoration: none;
  border-bottom: 0.05rem solid var(--darker-contrast-clr);
  padding: 0.5rem 0;
}

.category-description {
  font-size: 0.8rem;
  padding: 0.5rem 0;
}


.info-button {
  background-color: var(--contrast-clr);
  color: white;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.info-button:hover {
  background-color: var(--dark-clr);
  color: var(--complementary-clr);
}



@media only screen and (max-width: 425px) {
  .service-title {
    font-size: 18px;
  }
  .service-description {
    font-size: 12px;
  }
  .service-image {
    height: 150px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
  .info-button{
    margin-left: auto;
    margin-right: auto;
    max-width: 150px;
    min-height: 40px;
    display: inline-flex;
    font-size: 18px;
  }
  .service-info {
    margin: 0px 10px 0px 0px;
  }
  .info-icon{
    margin: 0px 20px 0px 10px;
    width: 32px;
  }
}
.link-button {
  text-decoration: none; /* Убирает подчеркивание */
  color: inherit;        /* Наследует цвет от кнопки */
  display: inline-block; /* Соответствует кнопке */
}
