/* === Основные стили страницы профиля === */
.profile-page {
  background-color: var(--primary-clr);
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.profile-page .headers .back-button-block {
  max-height: fit-content;
}

.user-profile {
  flex: 1;
  display: flex;
  flex-direction: column; /* Устанавливаем горизонтальную структуру */
}

.adding-navbar {
  margin-bottom: 2vh;
}

/* === Стили информации профиля (администратор/пользователь) === */
.admin-page, .user-info {
  text-align: center;
}

.admin-page {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.admin-page-tittle {
  text-align: center;
  margin-bottom: 5vh;
  font-size: 1.5rem;
}

.admin-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  min-width: 80vw;
}

.admin-navbar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 20vh;
  position: sticky;
  margin-top: 6.5vh;
}

.admin-content {
  width: 80vw;
}

.user-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.account-info {
  border-bottom: 1px solid #ccc;
  width: 100%;
  padding-bottom: 3vh;
}

/* === Кнопки на странице профиля === */
.admin-page button {
  background-color: var(--darker-complementary-clr);
  color: white;
  font-size: 18px;
  padding: 1vh 1.5vh;
  margin: 0.25rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.admin-page button:hover {
  background-color: var(--dark-clr);
  color: var(--complementary-clr);
}

.admin-page button.active {
  background-color: var(--contrast-clr);
}

.admin-page button.active:hover {
  color: white;
}

/* === Форма для добавления продуктов === */
.adding-block{
  display: flex;
  flex-wrap: wrap;
  /*flex-direction: column;*/
  justify-content: center;
}

.add-block{
  display: flex;
  justify-content: center;
  width: 60vw;
}

.add-form {
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 2vh;
  max-height: fit-content;
  min-width: 40vw;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.add-form h3 {
  font-size: 1.5rem;
  margin-bottom: 2vh;
}

.add-form .input-data,
.statistics-controls input{
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 5px; /* Добавлено, чтобы избежать перекрытий */
}

.add-form input,
.statistics-controls input,
.textarea textarea{
  display: block;
  width: 100%;
  height: 20px;
  border: none;
  font-size: 1rem;
  background: none;
  padding: 5px 0; /* Добавляет отступ сверху и снизу */
  border-bottom: 2px solid rgba(0, 0, 0, 0.12); /* Нормальный underline для начального состояния */
  transition: border-color 0.3s ease; /* Плавный переход */
}

.textarea textarea{
  resize: none;
  min-height: 10vh;
}

.add-form input:focus,
.add-form .textarea:focus,
.statistics-controls input:focus{
  outline: none;
  border-bottom: 2px solid var(--darker-complementary-clr); /* Меняем цвет при фокусе */
}

/* <select> styles */
select {
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 50%;
  padding: 1vh 4vh 1vh 1vh;
  background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em,
  linear-gradient(to left, var(--darker-contrast-clr) 3em, var(--darker-complementary-clr) 3em);
  color: white;
  border-radius: 10px;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
  /* Remove focus outline */
  &:focus {
    outline: none;
  }
  /* <option> colors */
  option {
    color: inherit;
    background-color: var(--complementary-clr);
  }
}

/* === Статус переключателя === */
.status-toggle{
  margin-bottom: 2vh;
}

.status-button-header {
  font-size: 1.5rem;
  margin-bottom: 1vh;
}

.status-toggle button {
  padding: 1vh 1.5vw;
  border: none;
  color: white;
  background: var(--darker-complementary-clr);
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.status-toggle button:not(.active):hover {
  background-color: var(--dark-clr);
  color: var(--complementary-clr);
}

/* === Список продуктов === */
.products-content .tittle {
  font-size: 1rem;
  margin-bottom: 2vh;
}

.list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.list-tittle {
  font-size: 1.25rem;
}

.list-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1vw;
  width: 80vw; /* Растягиваем на всю ширину */
  /*padding: 1rem;*/
}

.item {
  border-radius: 10px;
  width: 250px;
  text-align: center;
  padding: 1vw; /* Немного увеличим отступ для небольших экранов */
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  margin: 1vw;
}

.item img {
  border-radius: 10px;
  width: 250px;
  height: 250px;
  object-fit: cover;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* === Заказы для админа === */
.user-orders {
  align-content: center;
  margin: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.orders-container {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.orders-container {
  margin-bottom: 2vh;
}

.order-card,
.order-header {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
  align-items: center;
  background: #fff;
  padding: 2vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 80vw;
  font-size: 0.8rem;
}

.order-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

#order-id {
  font-size: 0.75rem;
}

.order-header, .order-card {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.order-header{
  background-color: var(--darker-complementary-clr);
  font-size: 1.25rem;
}

.order-card {
  grid-column: span 7;
}

.order-products {
  grid-template-columns: 1fr 1fr;
}

.order-products span {
  grid-column: 1 / -1;
  text-align: center;
}

.order-products ul li{
  gap: 1vh;
}

#product-price {
  border-bottom: 1px solid #cccccc;
}

.order-product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-product-item span:first-child {
  font-weight: bold;
}

/*Список всех пользователей на сайте*/
.users-container-header {
  font-size: 1rem;
  margin-bottom: 2vh;
}

.users-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-width: 100%;
  justify-content: center;
}

.users-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.user-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 2vh;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  background: #fff;
  padding: 2vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 80vw;
}

.user-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.user-item .user-info {
  width: 40vh;
}

.user-item .copy-email-button {
  display: flex;
  width: 40vh;
  justify-content: center;
  align-items: center;
}

.user-name {
  font-weight: 500;
}

.user-item .email {
  font-weight: 900;
}

/*История покупок пользователя*/
.purchases-container .purchases-header {
  background-color: var(--darker-complementary-clr);
}

.user-purchases {
  align-content: center;
  margin: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
}

.purchases-container {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.purchases-header,
.purchase-card {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
  align-items: center;
  background: #fff;
  padding: 2vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 80vw;
}

.purchase-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.purchase-id button {
  height: 3vh;
  width: 3vw;
  font-size: 0.75rem;
  color: white;
  background: var(--contrast-clr);
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.purchase-id button:hover {
  color: var(--complementary-clr);
  background: var(--dark-clr);
}

/*Shops buttons*/
.item .disable-button {
  /*background-color: indianred;*/
  /*color: white;*/
  color: var(--complementary-clr);
  background-color: var(--dark-clr);
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
}

.item .enable-button {
  background-color: var(--dark-clr);
  color: var(--complementary-clr);
  /*background-color: darkseagreen;*/
  /*color: white;*/
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
}

.item .disable-button:hover {
  /*background-color: var(--dark-clr);*/
  /*color: var(--complementary-clr);*/
  background-color: indianred;
  color: white;
}

.item .enable-button:hover {
  background-color: darkseagreen;
  color: white;
}

/*Statistics*/

.statistics-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statistics-header{
  font-size: 1rem;
  margin-bottom: 2vh;
}

.statistics-controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 20vw;
  text-align: center;
}

.statistics-controls input {
  text-align: center;
}

.statistics-controls button {
  max-width: fit-content;
  margin: 2vh;
}

.statistics-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Две диаграммы в ряд */
  gap: 2vh;
  margin: 2vh 0 2vh 0;
}

#sales-by-store {
  grid-column: span 2;
  width: 100%;
  max-width: 98%;
}

.chart-container {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  text-align: center;
  margin: 0 auto;
}

.chart-container canvas {
  max-height: 100%; /* Предотвращение растяжения canvas */
  max-width: 100%; /* Ограничение размера canvas */
}

/* Медиа-запросы для адаптации */
@media (max-width: 3840px) {
  .list-items {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 3vh;
  }
  .item {
    width: 90%;
  }
}

@media (max-width: 2000px) {
  .list-items {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3vh;
  }
  .item {
    width: 90%;
  }
}

@media (max-width: 992px) {
  .item {
    width: 90%;
  }

  .list-items {
    grid-template-columns: 1fr 1fr;
    gap: 2vh;
  }
}

@media (max-width: 768px) {
  .purchases-header,
  .purchase-card {
    font-size: 0.75rem;
  }

  .purchase-id button{
    font-size: 0.5rem;
    width: 10vw;
  }

  .item {
    width: 50%;
  }

  .list-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
  }
}

@media (max-width: 576px) {
  .purchases-header,
  .purchase-card {
    font-size: 0.65rem;
  }

  .purchase-id button{
    font-size: 0.4rem;
    width: 10vw;
  }

  .item {
    width: 100%;
  }

  .list-items {
    grid-template-columns: 1fr;
    gap: 1vh;
  }

  .item img {
    height: 15rem;
  }
}