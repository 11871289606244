#scrollTopBtn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 2rem;
    height: 2rem;
    color: white;
    background: var(--contrast-clr);
    border: none;
    border-radius: 50%;
    font-size: 10px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

#scrollTopBtn.show {
    opacity: 1;
    visibility: visible;
}

#scrollTopBtn:hover {
    background-color: var(--dark-clr);
    color: var(--complementary-clr);
}
